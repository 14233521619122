<template>
  <v-combobox
    v-model="input"
    :items="available"
    :item-value="itemValue"
    :item-text="itemText"
    :label="label"
    :disabled="disabled"
    chips
    :multiple="multiple"
    @input="myInput"
  >
    <template slot="selection" slot-scope="data">
      <v-chip
        :close="!disabled"
        @input="remove(data.item)"
      >
        <strong>{{ data.item.code }}</strong>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'BaseSelectChip',
  props: {
    value: Array | Object,
    label: String,
    available: Array,
    disabled: Boolean,
    itemText: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    input: {
      get() {
        // We need to modify the format depending on whether Included Biometrics
        // is a single or multiple value field, based on whether multiple is true or false.
        let values;
        if (!this.multiple) {
          // Use destructuring to get the object from the array
          [values] = this.value;
        } else {
          values = this.value;
        }
        return values;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    myInput(items) {
      // this.input = [...this.items];
      this.$emit('input', items);
    },
    remove(item) {
      // If the field using the chips is limited to a single value, item will be an
      // object, not an array, so we hae to convert it to an array first.
      let newInput = [];
      if (!Array.isArray(this.input)) {
        newInput[0] = this.input;
      } else {
        newInput = this.input;
      }
      const newItems = newInput.slice();
      newItems.splice(newInput.indexOf(item), 1);
      this.$emit('input', newItems);
    },
  },
};
</script>
